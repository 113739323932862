import {Dialog, Toast} from "vant";
export function getUrlKey(name,url){
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ""])[1].replace(/\+/g, '%20')) || null

}
export function message(messgae){
    Dialog.alert({
        message: messgae,
    }).then(() => {
        // on close
    })
}
export const loading = (flag) => {
    if (flag) {
        Toast.loading({
            mask: true,
            message: '加载中...'
        });
    } else {
        Toast.clear();
    }
}
/**
 * 字符串脱敏
 */
export function deseVal(str,frontLen,endLen,wildcard){
    if (!str) {
        return "--";
    } else if (str.length < 9) {
        return str;
    } else {
        var len = str.length - frontLen - endLen;
        var xing = "";
        for (var i = 0; i < len; i++) {
            xing += wildcard;
        }
        return (
            str.substring(0, frontLen) + xing + str.substring(str.length - endLen)
        );
    }
}

/**
 * 脱敏手机号码
 * @param str
 * @returns {string|*}
 */
export function desePhone(str){
    const frontLen = 3
    const endLen = 4
    const wildcard='*'
    if (!str) {
        return "--";
    } else if (str.length < 9) {
        return str;
    } else {
        var len = str.length - frontLen - endLen;
        var xing = "";
        for (var i = 0; i < len; i++) {
            xing += wildcard;
        }
        return (
            str.substring(0, frontLen) + xing + str.substring(str.length - endLen)
        );
    }
}
<template>
<div class="container">
  <div class="header-container">
    <header>
      <van-nav-bar
          :title="title"
          left-text="返回"
          right-text=""
          left-arrow
          @click-left="onBack"
      />
    </header>
  </div>
  <van-form @submit="onSubmit">
    <div class="section-box" style="padding: 5px">
      <van-field
          v-model="userInfo.account.phone"
          :formatter="phoneFormatter"
          name="phone"
          label="手机号码"
          left-icon="graphic"
          readonly
      />
      <van-field
          v-model="newPhone"
          type="digit"
          name="newPhone"
          label="新手机号码"
          left-icon="graphic"
          placeholder="新手机号码"
          required
          :rules="[{ validator, message: '' }]"
      />
      <SmsCode :phone-number="newPhone" verify-code="0" @smsCodeSate="smsCodeSate"></SmsCode>
      <div style="margin: 16px;">
        <van-button type="info" round block>修改</van-button>
      </div>
    </div>
  </van-form>
  <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
</div>
</template>

<script>
import {getKey, setKey} from "../../utils/cookie";
import SmsCode from "../../components/SmsCode";
import Loading from "../../components/logding";
import {modifyPhone} from "../../api";
import {deseVal} from "../../utils/tools";

export default {
  name: "modifyPhone",
  components: { SmsCode,Loading },
  data() {
    return {
      isShow: false,
      lodingTxt: '加载中...',
      title: '修改手机',
      userInfo:JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}'),
      oldPhoneDesc:'',
      newPhone:'',
      smsCode:''
    }
  },
  created() {

  },
  methods:{
    /**
     * 提交
     */
    onSubmit() {
      const that = this
      if (that.userInfo.account.phone == that.newPhone){
        that.$toast('修改的手机号码相同')
        return
      }
      that.lodingTxt = '正在保存...'
      that.isShow = true
      modifyPhone(that.userInfo.account.phone,that.newPhone,that.smsCode).then(response => {
        that.isShow = false
        that.$toast(response.message)
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.userInfo.account.phone = that.newPhone
          setKey(process.env.VUE_APP_USER_INFO,JSON.stringify(that.userInfo))
          that.newPhone = ''
          that.smsCode = ''
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    smsCodeSate(code){
      console.log('获取短信验证码:'+code)
      this.smsCode = code
    },
    onBack : function (){
      this.$router.go(-1);
    },
    validator(val) {
      return /1\d{10}/.test(val);
    },
    phoneFormatter(val){
      return deseVal(val,3,4,'*')
    }
  }
}
</script>

<style scoped>

</style>